export default {
  black: '#000',
  white: '#fff',
  primary: '#000',
  secondary: '#73737D',
  grey: '#73737D',
  tableHeading: '#eee',
  tableFooter: '#efefef',
  background: '#fafafa',
  accent: '#6166DC',
  // hover: 'rgba(0, 0, 0, 0.07)',
  gradient: 'linear-gradient(180deg, rgba(217, 219, 224, 0) 0%, #D9DBE0 100%)',
  articleText: '#08080B',
  articleTextLight: 'rgb(66,66,88)',
  valueText: '#000',
  // track: 'rgba(8, 8, 11, 0.3)',
  // progress: '#000',
  card: '#fff',
  cardAlt: '#fcfcfc',
  error: '#dc3545',
  success: 'rgb(0, 180, 0)',
  // errorBackground: 'rgba(238, 86, 91, 0.1)',
  horizontalRule: 'rgba(8, 8, 11, 0.15)',
  // inputBackground: 'rgba(0, 0, 0, 0.05)',
  modes: {
    dark: {
      black: '#090909',
      white: '#ddd',
      grey: '#73737D',
      tableHeading: 'rgb(48, 49, 52)',
      tableFooter: 'rgb(23, 23, 23)',
      primary: 'rgb(232, 234, 237)',
      secondary: '#fff',
      accent: 'rgb(160, 183, 246)',
      background: 'rgb(20, 21, 26)',
      // hover: 'rgba(255, 255, 255, 0.07)',
      gradient: 'linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.36) 100%)',
      articleText: 'rgb(189, 193, 198)',
      articleTextLight: 'rgb(120,125,135)',
      valueText: 'rgb(202, 206, 213)',
      // track: 'rgba(255, 255, 255, 0.3)',
      // progress: '#fff',
      card: 'rgb(32, 33, 36)',
      cardAlt: 'rgb(30, 31, 32)',
      error: 'rgb(248, 73, 96)',
      success: 'rgb(2, 192, 118)',
      // errorBackground: 'rgba(238, 86, 91, 0.1)',
      horizontalRule: 'rgb(23, 25, 26)'
      // inputBackground: 'rgba(255, 255, 255, 0.07)'
    }
  }
};
