// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-canada-tsx": () => import("./../../../src/templates/canada.tsx" /* webpackChunkName: "component---src-templates-canada-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-penny-tsx": () => import("./../../../src/templates/penny.tsx" /* webpackChunkName: "component---src-templates-penny-tsx" */),
  "component---src-templates-screenies-tsx": () => import("./../../../src/templates/screenies.tsx" /* webpackChunkName: "component---src-templates-screenies-tsx" */),
  "component---src-templates-spa-cs-tsx": () => import("./../../../src/templates/SPACs.tsx" /* webpackChunkName: "component---src-templates-spa-cs-tsx" */),
  "component---src-templates-ticker-tsx": () => import("./../../../src/templates/ticker.tsx" /* webpackChunkName: "component---src-templates-ticker-tsx" */),
  "component---src-templates-ws-bs-tsx": () => import("./../../../src/templates/WSBs.tsx" /* webpackChunkName: "component---src-templates-ws-bs-tsx" */)
}

